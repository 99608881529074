.tags-input-container {
  border: 1px solid #9c9c9c;
  padding: .5em;
  border-radius: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: .5em;
}

.tag-item {
  font-size: .8rem;
  background-color: rgba(218, 216, 216, 0.75);
  display: inline-block;
  padding: .25em .5em;
  border-radius: 2px;
}

.tag-item .close {
  height: 18px;
  width: 18px;
  /* background-color: rgb(48, 48, 48); */
  color: #000;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: .5em;
  font-size: 14px;
  cursor: pointer;
}

.tags-input {
  flex-grow: 1;
  padding: .5em 0;
  border: none;
  outline: none;
}

.tags-item-input {
  flex-grow: 1;
  padding: .5em;
  border: none;
  outline: none;
}
.tags-input-container{
  border: 1px solid #9c9c9c;
  padding: .5em;
  border-radius: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: .5em;
  position: relative;
  margin-bottom: 10px;
}

.tag-item{
  font-size: .8rem;
  background-color: rgba(218, 216, 216, 0.75);
  display: inline-block;
  padding: .25em .5em;
  border-radius: 2px;
}
.tag-item .close{
  height: 18px;
  width: 18px;
  /* background-color: rgb(48, 48, 48); */
  color: #000;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: .5em;
  font-size: 14px;
  cursor: pointer;
}

.tags-input{
  flex-grow: 1;
  padding: .5em 0;
  border: none;
  outline: none;
}

.selection-box {
  top: 103%;
  left: 0%;
  position: absolute;
  z-index: 1000;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.selection-item {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.selection-item:hover {
  background-color: #f0f0f0;
}